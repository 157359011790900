<template>
  <div
    class="agent-dropdown-list-item d-flex align-items-center"
    :class="{ active: agent.id === activeId }"
  >
    <UserAvatar
      :userType="UserType.Agent"
      :user="{ firstName: agent.firstName, lastName: agent.lastName }"
    />
    <p>{{ agent.name }}</p>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { UserAvatar, UserType } from '@bd/components'
import { AgentContent, AgentDropdownItem } from '@bd/admin/types'

export default defineComponent({
  name: 'AgentDropdownListItem',
  components: {
    UserAvatar,
  },
  props: {
    item: {
      type: Object as PropType<AgentContent>,
      required: true,
    },
    activeId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const agent = computed(
      (): AgentDropdownItem => {
        const fullNameSplit = props.item.name.split(' ')

        return {
          id: props.item.id,
          name: props.item.name,
          firstName: fullNameSplit[0],
          lastName: fullNameSplit[1],
        }
      },
    )

    return {
      agent,
      UserType,
    }
  },
})
</script>

<style lang="scss" scoped>
.agent-dropdown-list-item {
  height: 55px;
  &:not(:last-of-type) {
    border-bottom: 1px solid $athens-gray;
  }
  &:hover {
    cursor: pointer;
    color: $primary-color;
  }
}

.active {
  color: $primary-color;
}
</style>
