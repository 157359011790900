import { computed, ComputedRef } from 'vue'
import { AgentContent } from '../types'

export const useSelectedAgent = (
  selectedAgentFn: () => AgentContent | undefined,
): ComputedRef<AgentContent | null> =>
  computed(() => {
    const selected = selectedAgentFn()

    if (selected === undefined) {
      return null
    }

    const fullNameSplit = selected.name.split(' ')

    return {
      id: selected.id,
      name: selected.name,
      firstName: fullNameSplit[0],
      lastName: fullNameSplit[1],
    }
  })
