
import { computed, defineComponent, PropType } from 'vue'
import { UserAvatar, UserType } from '@bd/components'
import { AgentContent, AgentDropdownItem } from '@bd/admin/types'

export default defineComponent({
  name: 'AgentDropdownListItem',
  components: {
    UserAvatar,
  },
  props: {
    item: {
      type: Object as PropType<AgentContent>,
      required: true,
    },
    activeId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const agent = computed(
      (): AgentDropdownItem => {
        const fullNameSplit = props.item.name.split(' ')

        return {
          id: props.item.id,
          name: props.item.name,
          firstName: fullNameSplit[0],
          lastName: fullNameSplit[1],
        }
      },
    )

    return {
      agent,
      UserType,
    }
  },
})
