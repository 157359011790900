<template>
  <DropdownButton
    class="agent-dropdown"
    :class="{ selected: selectedAgent }"
    v-model:isOpen="isDropdownOpen"
    contentClass="button-dropdown-content"
  >
    <Button class="p-button-sm p-button-secondary p-button-rounded">
      <template v-if="selectedAgent">
        <UserAvatar
          class="avatar"
          :userType="UserType.Agent"
          :user="{
            firstName: selectedAgent.firstName,
            lastName: selectedAgent.lastName,
          }"
        />
        <span class="name-label">{{ selectedAgent.name }}</span>
        <i
          @click.stop="$emit('select', undefined)"
          class="pi pi-times ml-2 c-primary"
          v-if="showClearButton"
        />
      </template>
      <span class="name-label" v-else>{{ unselectedLabel }}</span>
      <i class="pi pi-chevron-down ml-2 c-primary"></i>
    </Button>
    <template #content>
      <AgentDropdownList
        :items="agents"
        :selectedAgentId="selectedAgent?.id"
        @dropdown-click="onDropdownClick"
      />
    </template>
  </DropdownButton>
</template>

<script lang="ts">
import AgentDropdownList from '@bd/admin/components/AgentDropdown/AgentDropdownList.vue'
import { AgentContent, AgentDropdownItem } from '@bd/admin/types'
import { UserType, DropdownButton } from '@bd/components'
import UserAvatar from '@bd/components/User/UserAvatar/UserAvatar.vue'
import { defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AgentDropdown',
  components: {
    DropdownButton,
    AgentDropdownList,
    UserAvatar,
  },
  props: {
    selectedAgent: {
      type: Object as PropType<AgentDropdownItem | null>,
      default: null,
    },
    agents: {
      type: Array as PropType<AgentContent[]>,
      required: true,
    },
    unselectedLabel: {
      type: String,
      default: '',
    },
    showClearButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['select'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const isDropdownOpen = ref(false)
    const onDropdownClick = (agentId: number) => {
      emit('select', agentId)
      isDropdownOpen.value = false
    }

    return {
      t,
      isDropdownOpen,
      onDropdownClick,
      UserType,
    }
  },
})
</script>

<style lang="scss" scoped>
.agent-dropdown {
  flex: 1 0 auto;
  max-width: min(40%, 280px);

  &:not(.selected-agent) {
    flex: 0 0 auto;
  }
}

.pi {
  color: $jungle-green;
}

:deep(.button-dropdown-content.dropdown-content) {
  top: 50px;
  padding: 25px;
  border-radius: 7px;
  box-shadow: 0 25px 190px 0 $light-shadow;
  background: #ffffff;
  z-index: 4;
  @media (min-width: 768px) {
    min-width: 400px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
}

.avatar {
  flex: 0 0 auto;
}
.name-label {
  flex: 1 1 auto;
}
</style>
