
import { defineComponent, PropType } from 'vue'
import { Agents, AgentContent } from '@bd/admin/types'
import AgentDropdownListItem from './AgentDropdownListItem.vue'

export default defineComponent({
  name: 'AgentDropdownList',
  emits: ['dropdown-click'],
  components: { AgentDropdownListItem },
  props: {
    items: {
      type: Object as PropType<Agents>,
      required: true,
    },
    selectedAgentId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectItem = (item: AgentContent) => {
      emit('dropdown-click', item.id)
    }

    return {
      selectItem,
    }
  },
})
