
import AgentDropdownList from '@bd/admin/components/AgentDropdown/AgentDropdownList.vue'
import { AgentContent, AgentDropdownItem } from '@bd/admin/types'
import { UserType, DropdownButton } from '@bd/components'
import UserAvatar from '@bd/components/User/UserAvatar/UserAvatar.vue'
import { defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AgentDropdown',
  components: {
    DropdownButton,
    AgentDropdownList,
    UserAvatar,
  },
  props: {
    selectedAgent: {
      type: Object as PropType<AgentDropdownItem | null>,
      default: null,
    },
    agents: {
      type: Array as PropType<AgentContent[]>,
      required: true,
    },
    unselectedLabel: {
      type: String,
      default: '',
    },
    showClearButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['select'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const isDropdownOpen = ref(false)
    const onDropdownClick = (agentId: number) => {
      emit('select', agentId)
      isDropdownOpen.value = false
    }

    return {
      t,
      isDropdownOpen,
      onDropdownClick,
      UserType,
    }
  },
})
